<template>
  <base-section id="astropsychology">
    <v-container>
      <v-row>
        <v-col
          cols="3"
          class="text-center hidden-sm-and-down"
        >
          <base-title
            class="primary--text"
            title="Fragst du dich manchmal... "
            tag="div"
          />
          <template v-for="(card) in cards">
            <base-avatar-card
              :key="card.title"
              align="center"
              v-bind="card"
            />

            <!--<v-divider
              v-if="i + 1 !== cards.length"
              :key="`divider-${i}`"
              class="my-8"
            />-->
          </template>
          <base-title
            class="primary--text"
            title="Die Astrologische Psychologie kann dir ganzheitliche Antworten auf Fragen wie diese bieten."
            tag="div"
          />
        </v-col>

        <v-col
          cols="2"
          class="hidden-sm-and-down text-center"
        >
          <v-responsive
            height="calc(100% - 16px)"
            class="mt-2"
          >
            <v-divider vertical />
          </v-responsive>
        </v-col>

        <v-col
          cols="12"
          md="7"
        >
          <base-subheading
            size="display-1"
            title="Astrologie & Psychologie"
            space="0"
          />

          <base-title
            class="primary--text"
            title="Was ist Astrologische Psychologie?"
            tag="div"
          />

          <base-body>
            Astrologische Psychologie hat nichts mit Wahrsagerei, Zeitschriftenhoroskopen oder verträumtem Sternegucken zu tun. Sie ist eine uralte Wissenschaft, die das 5'000 Jahre alte astrologische Symbolsystem mit der humanistische Psychologie vereint. Die Astrologie ist ein hervorragendes Instrument um den individuellen Charakter, den Rhythmus eines Menschen oder eines Ereignisses zu entschlüsseln und in grösseren Zusammenhängen zu erkennen.
          </base-body>

          <base-title
            class="primary--text"
            title="Was zeigt dein persönliches Horoskop?"
            tag="div"
          />

          <base-body>
            Jeder Mensch ist einzigartig und bringt sein unvergleichliches Potential in dieses Leben mit.
            Was du damit machst oder nicht machst, ist deinem freien Willen unterstellt.
            Aufgrund deiner persönlichen Geburtsdaten werden deine Stärken, Potentiale, Lebensaufgaben und Herausforderungen sichtbar. Dieses ganzheitliche Erfassen deiner Persönlichkeit zeigt dir Entwicklungsmöglichkeiten und Lösungsansätze auf.
          </base-body>

          <base-title
            class="primary--text"
            title="In welchen Themen kann mich astrologisch Psychology weiterbringen?"
            tag="div"
          />
          <v-row>
            <v-col
              v-for="(text, i) in marketing"
              :key="i"
              cols="12"
              md="6"
            >
              <base-list-item :text="text" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAstroPsychology',

    data: () => ({
      cards: [
        {
          title: '...wer du bist? ',
          // icon: 'mdi-zodiac-scorpio',
          outlined: true,
        },
        {
          title: '...oder was du sein kannst?',
          // icon: 'mdi-zodiac-scorpio',
          outlined: true,
        },
        {
          title: '...was in deinem Leben Sinn macht?',
          color: 'primary',
          dark: true,
          // icon: 'mdi-zodiac-gemini',
        },
        {
          title: '...wo deine Potentiale liegen?',
          // icon: 'mdi-zodiac-cancer',
          outlined: true,
        },
        {
          title: '...und wie du diese erfolgreich einsetzen kannst?',
          // icon: 'mdi-zodiac-cancer',
          outlined: true,
        },
      ],
      marketing: [
        'Beruf und Berufung',
        'Liebe und Partnerschaft',
        'Kinder',
        'Gesundheit',
        'Standortbestimmung',
        'Entscheidungshilfe',
        'Ganzheitliche Zusammenhänge erkennen',
        'Persönliche Potentiale',
        'Karmische Themen',
        'Jahreshoroskop',
      ],
    }),
  }
</script>
